import React from 'react'

import Layout from '../layouts/layout'
import SEO from '../components/seo'
import TitleHeader from '../components/titleheader'
import SecondaryNav from '../components/secondarynav'
import BorderedBox from '../components/borderedbox'

import headerimage from '../assets/images/whoweare/Corprate_WhoWeAre_01.jpg'
import mbheaderimage from '../assets/images/whoweare/Corprate_WhoWeAre_BoardofDirectors_MB_03.jpg'
import girlsimage from '../assets/images/whoweare/Corprate_WhoWeAre_aboutus_01.jpg'
import { trackPageData, getPageDetails } from '../analytics'

class AboutUs extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="About Us | The Children's Place Corporate Website"
          description="Learn more about the history of The Children's Place on The Children's Place Corporate Website."
        />

        {/* Page Title & Header Image */}
        <div class="row mb-12">
          <div class="col-12">
            <TitleHeader
              image={headerimage}
              mbheaderimage={mbheaderimage}
              title="WHO WE ARE"
            />
          </div>
        </div>
        {/* End Page Title & Header Image */}

        <SecondaryNav
          active="About Us"
          name1="About Us"
          link1="/about-us"
          name2="Senior Leadership"
          link2="/senior-leadership"
          name3="Board of Directors"
          link3="/board-of-directors"
          name4="International Franchises"
          link4="/international-franchises"
        />

        <div class="row mb-12 no-gutters">
          <div class="col-lg-12 col-md-12 col-sm-12 fill d-lg-block">
            <img src={girlsimage} className="img-fluid col-12" alt="" />
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 text-left">
            <br />
            <BorderedBox classname="mb-n-border text-left ">
              <p>
                The Children’s Place was founded in Hartford, Connecticut in
                1969 by David Pulver and Clinton Clark. They began by selling
                toys, apparel and accessories before deciding that apparel had
                the most growth potential.
              </p>

              <p>
                By the early 1980s, The Children’s Place was repositioned as a
                specialty retailer of children’s apparel for newborns to
                pre-teens and began offering private label merchandise as well
                as branded product.
              </p>

              <p>
                In 2019, The Children’s Place purchased the rights to the
                Gymboree brand and in early 2020, launched product in select
                stores and online. This iconic brand features colorful designs
                in playful, bow-to-toe collections that celebrate childhood and
                help families look their best for any occasion. In late 2021,
                the Sugar &amp; Jade brand was launched featuring collections
                tailored to celebrate tween girls’ individuality. In late 2022,
                the PJ Place brand was launched as a one-stop pajama destination
                for all ages, including adults. Together, our four brands
                support growing families across every demographic with apparel
                that builds confidence and promotes inclusivity.
              </p>
            </BorderedBox>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutUs
